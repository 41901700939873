<template>
    <div class="aa-primera-noticia mb-2 fade-in" @click="ir">
        <div class="aa-primera-noticia-img">
            <img :src="noticia.portada" >
        </div>
        <div class="aa-primera-noticia-titulo aa-text-h2 px-1 text-start">
            {{noticia.titulo}}
        </div>
    </div>
    
</template>

<script>
import { useRouter } from 'vue-router'
export default {
props: {
    datos: {
        type: Object,
        default(){
            return {}
        }
    },
    url: {
        type: String,
        required: true
    }
},
setup(props){

    const router = useRouter()
    return {
        noticia: props.datos,
        ir: () => router.push({ name: props.url, params: { id: props.datos.id } })
    }
}

}
</script>

<style lang="sass" scoped>
.aa-primera-noticia
    $root:&
    cursor: pointer
    &-img
        img
            width: 100%
        @include breakpoint(md)
            height: 390px
            overflow: hidden
</style>

